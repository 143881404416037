.footer {
    background-color: #f8f9fa; /* Example background color for the footer */
    padding: 20px 0;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: center;
}

.footer-link {
    text-decoration: none; /* Remove underline */
    color: #333; /* Change the color to a neutral tone */
    font-size: 16px;
    font-weight: bold;
}

.footer-link:hover {
    color: #555; /* Add a hover color effect */
}
