/* InvestmentSimulator.css */

/* Base container styles */
.card-container {
    display: block;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 1200px;
  }
  
  /* Title styling */
  .card-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Card content */
  .card-content {
    display: block;
  }
  
  /* Input slider styles */
  input[type="range"] {
    width: 100%;
    margin: 10px 0;
    appearance: none;
    height: 8px;
    background: #ddd;
    border-radius: 4px;
    outline: none;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  label {
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
    color: #555;
  }
  
  /* Results table */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  }
  
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    font-weight: bold;
    background-color: #f4f4f4;
    color: #333;
  }
  
  td {
    text-align: right;
    color: #555;
  }
  
  tbody tr:nth-child(odd) {
    background-color: #fdfdfd;
  }
  
  /* Mobile-specific styles */
  @media only screen and (max-width: 768px) {
    .card-container {
      padding: 15px;
      border: 1px solid #ccc;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    }
  
    .card-title {
      font-size: 20px;
      text-align: center;
    }
  
    label {
      font-size: 14px;
    }
  
    table {
      font-size: 14px;
    }
  
    th,
    td {
      padding: 8px;
    }
  
    tbody tr:nth-child(odd) {
      background-color: #fff;
    }
  }
  