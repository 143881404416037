
  
  /* Scrollbar styles */
  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    border-radius: 10px !important;
    border: 4px solid #f1f1f1 !important;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
  }
  
  /* For Firefox */
  * {
    scrollbar-width: 20px;
    scrollbar-color: #888 #f1f1f1 !important;
  }
  