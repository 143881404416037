.licensee-services-container {
    text-align: center;
    padding: 20px;
    height: 100vh; /* Ensure the container takes the full height of the viewport */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    max-width: 800px;
    margin: 0 auto;
    flex-grow: 1; /* Allow grid to grow within the container */
}

.service-box {
    background-color: #f0f0f0;
    padding: 10px; /* Reduce padding for a more compact layout */
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.8rem; /* Reduce font size for better fit */
}

.service-box:hover {
    background-color: #dcdcdc;
}

.service-box h2 {
    margin-bottom: 5px;
    font-size: 1rem; /* Reduce header size for compactness */
}

.house-rules-link {
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
    font-size: 0.8rem; /* Adjust font size for mobile */
    margin-top: 10px;
}

.house-rules-link:hover {
    background-color: #d3d3d3;
}

/* Mobile Styling */
@media (max-width: 600px) {
    .services-grid {
        grid-template-columns: 1fr 1fr; /* Keep grid structure but reduce spacing */
        gap: 5px; /* Reduce gap between elements */
    }

    .service-box {
        padding: 8px; /* Further reduce padding for compact fit */
        font-size: 0.7rem; /* Further reduce font size */
    }

    .house-rules-link {
        padding: 8px;
        font-size: 0.7rem; /* Make the house rules link smaller */
    }

    h1 {
        font-size: 1.2rem; /* Reduce the size of h1 for mobile */
        margin-bottom: 10px;
    }
}