/* AdminRentPage.css */

/* Mobile styles */
@media only screen and (max-width: 768px) {
    .card-container {
      display: block;
      margin-bottom: 15px;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;
      background-color: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
  
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      background-color: #f4f4f4;
      border-bottom: 1px solid #ddd;
    }
  
    .card-content {
      display: none;
      padding: 10px;
    }
  
    .card-container.open .card-content {
      display: block;
    }
  
    .card-title {
      font-weight: bold;
    }
  }
  