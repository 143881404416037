.table-page-container {
    /* Container that can be as large or small as you like */
    max-width: 100%;
    margin: 0 auto; 
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Button area styling */
  .button-group {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .button-group button {
    cursor: pointer;
    padding: 0.5rem 1rem;
  }
  
  /* Table container with scrollbars if needed */
  .table-container {
    overflow-x: auto;
    max-height: 70vh; /* adjust to taste */
    border: 1px solid #ccc;
  }
  
  /* Basic responsive table */
  .responsive-table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.9rem;
  }
  
  .responsive-table th,
  .responsive-table td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
  }
  
  .responsive-table th {
    background-color: #f4f4f4;
  }
  
  .loading,
  .warning,
  .error {
    text-align: center;
    margin: 2rem;
    font-size: 1.1rem;
  }
  
  